import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { motion } from 'framer-motion';
import { upperFirst } from 'lodash';

import { track } from 'common/analytics';
import {
  cryptoNames,
  isCrypto,
  cryptoChains,
  payoutFieldsForCurrency,
} from 'common/currencies';
import Button from 'common/components/Button';
import Dropdown from 'common/components/Dropdown';
import Label from 'common/components/Label';
import Text from 'common/components/Text';
import TextBox from 'common/components/TextBox';
import TextArea from 'common/components/TextArea';

import formUtils from 'common/formUtils.js';
import { payoutLabels, payoutPlaceholders } from 'onboarding/utils/constants';

import styles from './index.module.css';

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const initialValues = {
  bankAccountNumber: '',
  bankCountry: '',
  cryptoWalletAddress: '',
  iban: '',
  routingNumber: '',
  sectorCategory: '',
  sector: '',
  businessDescription: '',
  sortCode: '',
  swiftCode: '',
  variableAccountTypeNumber: '',
};

const handleVariableValues = (setFieldValue, values) => {
  if (values.variableAccountTypeNumber !== '') {
    if (formUtils.isAccountNumberIBAN(values.variableAccountTypeNumber)) {
      setFieldValue('iban', values.variableAccountTypeNumber);
    } else {
      setFieldValue('bankAccountNumber', values.variableAccountTypeNumber);
    }
  }
};

const submitForm = (handleSubmit, setFieldValue, values) => (e) => {
  e.preventDefault();
  handleVariableValues(setFieldValue, values);
  handleSubmit(e);
};

const BankField = ({ fieldName, setFieldValue }) => (
  <>
    <Field
      name={fieldName}
      validate={(value) => formUtils.validateRequired(value)}
    >
      {({ field, meta }) => (
        <div className={styles.row}>
          <TextBox
            id={fieldName}
            labelText={payoutLabels[fieldName]}
            labelType={fieldName}
            placeholder={payoutPlaceholders[fieldName]}
            error={meta.touched && meta.error}
            errorText={meta.error}
            {...field}
            onChange={(e) => {
              setFieldValue(fieldName, e.target.value.trim());
            }}
          />
        </div>
      )}
    </Field>
    <br />
  </>
);

const BusinessDetailsForm = ({
  companyOfficialName,
  countries,
  currency,
  blockchain,
  errorMessage,
  onSubmit,
  sectorData,
}) => {
  const [selectedBankCountry, setSelectedBankCountry] = useState();
  const [bankFields, setBankFields] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const isCryptoSettlement = isCrypto(currency);

  useEffect(() => {
    setBankFields(payoutFieldsForCurrency(currency, selectedBankCountry));
  }, [selectedBankCountry]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, setFieldValue, setValues, values }) => (
        <form onSubmit={submitForm(handleSubmit, setFieldValue, values)}>
          {countries && sectorData && (
            <motion.div initial="hidden" animate="visible" variants={variants}>
              <Field
                name="sectorCategory"
                validate={formUtils.validateRequired}
              >
                {({ meta }) => (
                  <div className={styles.row}>
                    <Label>Sector</Label>
                    <Dropdown
                      id="sectorCategory"
                      name="sectorCategory"
                      placeholder="Select your business sector"
                      initialSelectedItem={meta.initialValue}
                      items={sectorData.categories || []}
                      error={!!(meta.touched && meta.error)}
                      errorText={meta.error}
                      onChange={(value) => {
                        setSelectedCategory(value);
                        setFieldValue('sectorCategory', value);
                        setFieldValue('sector', '');
                      }}
                    />
                  </div>
                )}
              </Field>
              <Field name="sector" validate={formUtils.validateRequired}>
                {({ meta }) => {
                  const items = selectedCategory
                    ? sectorData.sectorsByCategory[selectedCategory]
                    : [];

                  return (
                    <div
                      className={classNames(
                        styles.row,
                        styles.sectorWrapper,
                        !selectedCategory && styles.disabled
                      )}
                    >
                      <Label>Sub Sector</Label>
                      <Dropdown
                        key={selectedCategory}
                        id="sector"
                        name="sector"
                        placeholder={
                          selectedCategory
                            ? 'Select your business sub sector'
                            : 'Select sector first'
                        }
                        items={items}
                        error={!!(meta.touched && meta.error)}
                        errorText={meta.error}
                        onChange={(value) => {
                          track('Sector Selected', {
                            sector: value,
                          });
                          setFieldValue('sector', value);
                        }}
                        disabled={!selectedCategory}
                      />
                    </div>
                  );
                }}
              </Field>
              <Field
                name="businessDescription"
                validate={(value) =>
                  formUtils.validateBusinessDescription(value)
                }
              >
                {({ meta, field }) => (
                  <div
                    className={classNames(
                      styles.row,
                      styles.businessDescription
                    )}
                  >
                    <TextArea
                      id="businessDescription"
                      name="businessDescription"
                      labelText="Business description"
                      placeholder="Describe the activities your business conducts"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      {...field}
                    />
                  </div>
                )}
              </Field>
              <hr className={styles.divider} />
              {isCryptoSettlement ? (
                <>
                  <Text>Please add your {currency} details below</Text>
                  <br />
                  <Field
                    name={'cryptoWalletAddress'}
                    validate={(value) => formUtils.validateRequired(value)}
                  >
                    {({ field, meta }) => (
                      <div className={styles.row}>
                        <TextBox
                          id={'cryptoWalletAddress'}
                          labelText={`${upperFirst(
                            cryptoNames(currency)
                          )} address (${cryptoChains[blockchain]} Network)`}
                          placeholder={`Your company's ${cryptoNames(
                            currency
                          )} address`}
                          error={meta.touched && meta.error}
                          errorText={meta.error}
                          {...field}
                          onChange={(e) => {
                            setFieldValue(
                              'cryptoWalletAddress',
                              e.target.value.trim()
                            );
                          }}
                        />
                      </div>
                    )}
                  </Field>
                  <div className={styles.exchangeWarning}>
                    <Text
                      size="caption"
                      color="rebranding-md-color-orange"
                      weight="semiBold"
                    >
                      {`Make sure the address is from ${cryptoChains[blockchain]} Network and not from Kraken exchange!`}
                      {` Using an incorrect address results in permanent
                      losses.`}
                    </Text>
                  </div>
                  <br />
                </>
              ) : (
                <>
                  <Text>Please add your {currency} bank details below</Text>
                  <br />
                  <Text color="rebranding-md-color-grey-70">
                    {`Make sure the bank account's owner is`}{' '}
                    <span className={styles.bold}>{companyOfficialName}.</span>
                  </Text>
                  <br />
                  <Field
                    name="bankCountry"
                    validate={(value) => formUtils.validateRequired(value)}
                  >
                    {({ meta }) => (
                      <div className={styles.row}>
                        <Label>Bank country</Label>
                        <Dropdown
                          isSearchable
                          id="bankCountry"
                          name="bankCountry"
                          placeholder="Choose your bank's country"
                          initialSelectedItem={meta.initialValue}
                          items={countries || []}
                          error={!!(meta.touched && meta.error)}
                          errorText={meta.error}
                          onChange={(value) => {
                            track('Bank Country Selected', {
                              bankCountry: value,
                            });
                            setSelectedBankCountry(value);
                            setValues({
                              ...initialValues,
                              sectorCategory: values.sectorCategory,
                              sector: values.sector,
                              businessDescription: values.businessDescription,
                              bankCountry: value,
                            });
                          }}
                        />
                      </div>
                    )}
                  </Field>
                  <br />
                  {selectedBankCountry &&
                    bankFields.map((fieldName) => (
                      <BankField
                        key={fieldName}
                        fieldName={fieldName}
                        setFieldValue={setFieldValue}
                      />
                    ))}
                </>
              )}
              <div className={styles.formFooter}>
                <Text color="rebranding-md-color-red">
                  {errorMessage || ''}
                </Text>
                <div className={styles.buttonWrapper}>
                  <Button
                    name="submit-business-details-button"
                    full
                    kind="primary"
                  >
                    <button
                      type="submit"
                      id="progress-status"
                      data-test="submit-business-details-button"
                    >
                      Next
                    </button>
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </form>
      )}
    </Formik>
  );
};

const dropdownOption = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

BusinessDetailsForm.propTypes = {
  companyOfficialName: PropTypes.string,
  countries: PropTypes.arrayOf(dropdownOption),
  currency: PropTypes.string,
  blockchain: PropTypes.string,
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  sectorData: PropTypes.shape({
    categories: PropTypes.arrayOf(dropdownOption),
    sectorsByCategory: PropTypes.objectOf(PropTypes.arrayOf(dropdownOption)),
  }),
};

export default BusinessDetailsForm;
