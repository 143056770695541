import _ from 'lodash';

const mapCountries = (response, isAlpha2 = false) =>
  _.chain(response)
    .values()
    .map((country) => ({
      label: country.attributes.name,
      value: country.attributes[isAlpha2 ? 'alpha2' : 'alpha3'],
    }))
    .sortBy((country) => country.label.toLowerCase())
    .value();

const mapSectors = (response, includeAllSectors = false) => {
  if (
    !response ||
    typeof response !== 'object' ||
    Object.keys(response).length === 0
  ) {
    return { categories: [], sectorsByCategory: {} };
  }

  const sectorsByCategory = _.chain(response)
    .values()
    .filter((sector) => {
      if (!sector || !sector.attributes) {
        return false;
      }

      return (
        includeAllSectors || sector.attributes.industrySector !== 'Sectors'
      );
    })
    .groupBy((sector) => sector.attributes.industrySector)
    .mapValues((sectors) =>
      sectors.map((sector) => ({
        label: sector.attributes.division,
        value: sector.id,
      }))
    )
    .value();

  const categories = Object.keys(sectorsByCategory).map((category) => ({
    label: category,
    value: category,
  }));

  return { categories, sectorsByCategory };
};

export { mapCountries, mapSectors };
