import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { snakeCase } from 'lodash';

import { track } from 'common/analytics';
import { useOnMountDataRequest } from 'common/hooks';
import { api } from 'app/services';
import { actions as errorActions } from 'error';
import {
  businessDetailsEnteredIntegration,
  kybT1Submitted,
} from 'common/constants';
import { attachment as attachmentType } from 'common/propTypes';
import Text from 'common/components/Text';
import { selectors as sessionSelectors } from '../../session';
import OnboardingHeading from '../components/OnboardingHeading';
import BusinessDetailsForm from '../components/BusinessDetailsForm';
import BusinessDocumentsForm from '../components/BusinessDocumentsForm';
import { kybDocumentsByType } from '../reducers';
import useBusinessDetailsFetch from '../utils/useBusinessDetailsFetch';
import { activeStore as activeStoreSelector } from '../../session/reducers';

import actions from '../actions';

const checkMissingFields = (fields, listByType) => {
  const newMissingFields = [];

  fields.forEach((a) => {
    if (!listByType[snakeCase(a.name)] && !a.name.endsWith('Heading'))
      newMissingFields.push(a.name);
  });
  return newMissingFields;
};

const BusinessDetailsContainer = ({
  apiError,
  companyOfficialName,
  listByType,
  merchantId,
  integrationStatus,
  storeId,
  updateStatus,
}) => {
  const { isMounting } = useOnMountDataRequest(actions.kybDocumentsRequest);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [missingFields, setMissingFields] = useState([]);

  useEffect(() => {
    setMissingFields([]);
    setErrorMessage();
  }, [listByType]);

  const [
    businessDetails,
    countries,
    currency,
    blockchain,
    isLoading,
    networkError,
    sectorData,
  ] = useBusinessDetailsFetch();

  const handleSubmitDocs = async (companyType, fields) => {
    setIsSubmitting(true);
    setErrorMessage();
    setMissingFields([]);

    track('Submitted Business Details');

    const newMissingFields = checkMissingFields(fields, listByType);

    if (newMissingFields.length) {
      setIsSubmitting(false);
      setErrorMessage('Please upload the necessary documents.');
      setMissingFields(newMissingFields);
      return;
    }

    const { error } = await api.onboardingSubmitCompanyType({
      companyType,
      merchantId,
    });

    if (!error) {
      setErrorMessage('');
      setIsSubmitting(false);
      return updateStatus(kybT1Submitted);
    }

    apiError(error);
    setErrorMessage('Could not submit your business documents.');
    track('Error returned during Business Details submission');
    setIsSubmitting(false);
  };

  const handleSubmitDetails = async (values) => {
    const { error } = await api.submitBusinessDetails(values, storeId);
    if (!error) {
      setErrorMessage('');
      return updateStatus(businessDetailsEnteredIntegration);
    }

    apiError(error);
    setErrorMessage('Could not submit your business details.');
  };

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    const shouldSkipFirstStep = () => {
      if (!businessDetails?.sectorId) return false;
      return (
        (businessDetails?.iban && businessDetails?.swiftCode) ||
        (businessDetails?.bankAccountNumber &&
          businessDetails?.routingNumber) ||
        (businessDetails?.bankAccountNumber && businessDetails?.swiftCode)
      );
    };

    if (shouldSkipFirstStep()) {
      updateStatus(businessDetailsEnteredIntegration);
    }
  }, [businessDetails]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  if (isMounting || isLoading) return null;
  if (networkError) return <Text>Something went wrong</Text>;

  return (
    <div id="businessDetails">
      <OnboardingHeading
        title={
          integrationStatus === businessDetailsEnteredIntegration
            ? 'Verification Documents'
            : 'Business Details'
        }
        dataTest="businessDetails-header"
      />
      {integrationStatus === businessDetailsEnteredIntegration ? (
        <BusinessDocumentsForm
          onSubmit={handleSubmitDocs}
          submitting={isSubmitting}
          errorMessage={errorMessage}
          missingFields={missingFields}
        />
      ) : (
        <BusinessDetailsForm
          currency={currency}
          blockchain={blockchain}
          companyOfficialName={companyOfficialName}
          countries={countries}
          errorMessage={errorMessage}
          sectorData={sectorData}
          onSubmit={handleSubmitDetails}
        />
      )}
    </div>
  );
};

BusinessDetailsContainer.propTypes = {
  apiError: PropTypes.func.isRequired,
  companyOfficialName: PropTypes.string,
  listByType: PropTypes.objectOf(PropTypes.arrayOf(attachmentType)).isRequired,
  merchantId: PropTypes.string,
  integrationStatus: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  updateStatus: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    companyOfficialName:
      sessionSelectors.activeStore(state)?.companyOfficialName,
    listByType: kybDocumentsByType(state),
    merchantId: sessionSelectors.activeOrganization(state)?.id,
    storeId: activeStoreSelector(state)?.id,
  }),
  {
    apiError: errorActions.apiError,
  }
)(memo(BusinessDetailsContainer));
