import { reduce, isNaN } from 'lodash';

const INVOICE_DESCRIPTION_MIN_LENGTH = 10;
const INVOICE_DESCRIPTION_MAX_LENGTH = 1000;
const BUSINESS_DESCRIPTION_MIN_LENGTH = 20;
const BUSINESS_DESCRIPTION_MAX_LENGTH = 500;
const NAME_MAX_LENGTH = 35;

const emailRegex = /^[\S]+@[\S]+\.[\S]+$/;
const urlRegex = /([\S]+\.)+[\S]+/;
const passwordRegex =
  /^(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[0-9])(?=.*[a-z])/;
export const REQUIRED_MESSAGE = 'This field is required';
export const INVALID_EMAIL_MESSAGE = 'Invalid email address';
export const INVALID_URL_MESSAGE = 'Invalid URL';
export const SHORT_PASSWORD_MESSAGE = 'Password must be at least 8 characters';
export const LONG_PASSWORD_MESSAGE = 'Password must be at most 64 characters';
export const SIMPLE_PASSWORD_MESSAGE =
  'Must be at least 8 characters long, contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.';
export const INVALID_TWO_FACTOR_MESSAGE =
  '2 factor authentication code must be 6 digits';
export const MUST_BE_NUMBER = 'Must be a number';
export const MUST_BE_POSITIVE = 'Must be higher than 0';

const SHORT_INVOICE_DESCRIPTION = `Description needs to be bigger than ${INVOICE_DESCRIPTION_MIN_LENGTH} characters.`;
const LONG_INVOICE_DESCRIPTION = `Description needs to be lower than ${INVOICE_DESCRIPTION_MAX_LENGTH} characters.`;
const SHORT_BUSINESS_DESCRIPTION = `Description needs to be bigger than ${BUSINESS_DESCRIPTION_MIN_LENGTH} characters.`;
const LONG_BUSINESS_DESCRIPTION = `Description needs to be lower than ${BUSINESS_DESCRIPTION_MAX_LENGTH} characters.`;
const LONG_NAME = `Must be ${NAME_MAX_LENGTH} characters or less`;

const testEmail = (email) => emailRegex.test(email);

const testUrl = (url) => urlRegex.test(url);

const testPassword = (password) => passwordRegex.test(password);

const checkBoxValue = (value) => {
  if (!value) return false;
  return value[0] === 'on';
};

const validateRequired = (value) => {
  if (!value || !value.length) return REQUIRED_MESSAGE;
};

const validateCountryObject = (obj) => {
  if (!obj || !obj.value.length) return REQUIRED_MESSAGE;
};

const validatePassword = (value) => {
  if (!value) return REQUIRED_MESSAGE;
  if (!testPassword(value)) return SIMPLE_PASSWORD_MESSAGE;
  if (value.length < 8) return SHORT_PASSWORD_MESSAGE;
  if (value.length > 64) return LONG_PASSWORD_MESSAGE;
};

const validateTwoFactorCode = (value) => {
  if (!value) return REQUIRED_MESSAGE;
  if (value.length !== 6 || isNaN(Number(value)))
    return INVALID_TWO_FACTOR_MESSAGE;
};

const validateEmail = (value) => {
  if (!value) return REQUIRED_MESSAGE;
  if (!testEmail(value)) return INVALID_EMAIL_MESSAGE;
};

const validateUrl = (value) => {
  if (value && !testUrl(value)) return INVALID_URL_MESSAGE;
};

const validateRequiredUrl = (value) => {
  if (!value) return REQUIRED_MESSAGE;
  return validateUrl(value);
};

const validateNumber = (value) => {
  if (value && isNaN(Number(value))) return MUST_BE_NUMBER;
};

const validatePositiveAmount = (value) => {
  const numericValue = Number(value);

  if (!isNaN(numericValue) && numericValue <= 0) return MUST_BE_POSITIVE;
};

const validateMaxAmountValue = (max) => (value) => {
  const numericValue = Number(value);
  const numericMax = Number(max);

  if (!isNaN(numericValue) && numericValue > numericMax)
    return `Must be at maximum ${max}`;
};

const validateRefundAmount = (max) => (value) =>
  validateNumber(value) ||
  validatePositiveAmount(value) ||
  validateMaxAmountValue(max)(value);

const validateInvoiceDescription = (value) => {
  if (!value || !value.length) return REQUIRED_MESSAGE;

  if (value.length < INVOICE_DESCRIPTION_MIN_LENGTH) {
    return SHORT_INVOICE_DESCRIPTION;
  }

  if (value.length > INVOICE_DESCRIPTION_MAX_LENGTH) {
    return LONG_INVOICE_DESCRIPTION;
  }
};

const validateBusinessDescription = (value) => {
  if (!value || !value.length) return REQUIRED_MESSAGE;

  if (value.length < BUSINESS_DESCRIPTION_MIN_LENGTH) {
    return SHORT_BUSINESS_DESCRIPTION;
  }

  if (value.length > BUSINESS_DESCRIPTION_MAX_LENGTH) {
    return LONG_BUSINESS_DESCRIPTION;
  }
};

const isSubmitDisabled = (errors, touched) => {
  const touchedFields = reduce(
    touched,
    (acc, isTouched, fieldKey) => {
      if (isTouched) acc.push(fieldKey);
      return acc;
    },
    []
  );
  const fieldsWithErrors = reduce(
    errors,
    (acc, field, fieldKey) => {
      if (touchedFields.includes(fieldKey)) acc.push(fieldKey);
      return acc;
    },
    []
  );

  return fieldsWithErrors.length > 0;
};

const isAccountNumberIBAN = (accountNumber) =>
  /[A-Z]{2}[0-9]*/.test(accountNumber);

const validateName = (value) => {
  if (!value) return REQUIRED_MESSAGE;
  if (value.length > NAME_MAX_LENGTH) return LONG_NAME;
};

export default {
  checkBoxValue,
  isSubmitDisabled,
  isAccountNumberIBAN,
  testEmail,
  validateCountryObject,
  validateEmail,
  validateMaxAmountValue,
  validateNumber,
  validatePassword,
  validatePositiveAmount,
  validateRefundAmount,
  validateRequired,
  validateRequiredUrl,
  validateTwoFactorCode,
  validateUrl,
  validateInvoiceDescription,
  validateBusinessDescription,
  validateName,
};
