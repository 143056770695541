import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import _ from 'lodash';
import build from 'redux-object';

import { mapSectors, mapCountries } from 'onboarding/utils/signUpUtils.js';
import actions from '../actions';

const {
  ACCOUNT_PROFILE_SUCCESS,
  ACCOUNT_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  TWO_FACTOR_AUTH_CHANGE,
  BUSINESS_INFO_SUCCESS,
  SECTORS_SUCCESS,
  CONTRACT_SUCCESS,
  PAYOUT_ACCOUNTS_SUCCESS,
  MERCHANT_COUNTRIES_SUCCESS,
} = actions;

const profile = handleActions(
  {
    [ACCOUNT_PROFILE_SUCCESS]: (state, { payload }) => {
      const settings = _.first(_.values(payload.accountSettings));
      return { id: settings.id, ...settings.attributes };
    },
    [UPDATE_PROFILE_SUCCESS]: (state, { payload }) => {
      const newSettings = _.first(_.values(payload.accountSettings)).attributes;
      return { ...state, ...newSettings };
    },
    [TWO_FACTOR_AUTH_CHANGE]: (state, { payload }) => ({
      ...state,
      tfaActive: payload?.tfaActive,
    }),
    [ACCOUNT_PROFILE_FAILURE]: () => ({ error: true }),
  },
  null
);

const isUpdatingProfileReducer = handleActions(
  {
    [combineActions(UPDATE_PROFILE_REQUEST)]: () => true,
    [combineActions(UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE)]: () =>
      false,
  },
  false
);

const initialValuesTwoFactor = {
  keyUri: null,
  secret: null,
  recoveryCode: null,
};
const twoFactor = handleActions(
  {
    [TWO_FACTOR_AUTH_CHANGE]: (state, { payload }) => ({
      ...state,
      keyUri: payload?.keyUri,
      recoveryCode: payload?.recoveryCode,
      secret: payload?.secret,
    }),
  },
  initialValuesTwoFactor
);

const businessInfo = handleActions(
  {
    [BUSINESS_INFO_SUCCESS]: (state, { payload }) =>
      _.first(_.values(payload.companyInfo)).attributes,
  },
  null
);

const sectors = handleActions(
  {
    [SECTORS_SUCCESS]: (state, { payload }) => mapSectors(payload.sector, true),
  },
  null
);

const contract = handleActions(
  {
    [CONTRACT_SUCCESS]: (state, { payload }) =>
      _.first(_.values(payload.contract)).attributes,
  },
  null
);

const payoutAccounts = handleActions(
  {
    [PAYOUT_ACCOUNTS_SUCCESS]: (state, { payload }) => {
      const obj = build(payload, 'payoutAccounts');

      return _.merge(
        state || {},
        _.reduce(
          obj,
          (result, account) => ({
            ...result,
            [account.id]: account,
          }),
          []
        )
      );
    },
  },
  null
);

const countries = handleActions(
  {
    [MERCHANT_COUNTRIES_SUCCESS]: (state, { payload }) =>
      mapCountries(payload.country),
  },
  null
);

export const getBusinessInfo = (state) => state.settings.businessInfo;
export const getContract = (state) => state.settings.contract;
export const getCountries = (state) => state.settings.countries;
export const getPayoutAccounts = (state) => state.settings.payoutAccounts;
export const getProfile = (state) => state.settings.profile;
export const getSectors = (state) => state.settings.sectors;
export const getTwofactor = (state) => state.settings.twoFactor;
export const isFetchingProfile = (state) => state.settings.isFetchingProfile;

export default combineReducers({
  businessInfo,
  contract,
  countries,
  isFetchingProfile: isUpdatingProfileReducer,
  payoutAccounts,
  profile,
  sectors,
  twoFactor,
});
