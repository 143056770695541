import { useState, useEffect } from 'react';
import _ from 'lodash';

import { api } from 'app/services';
import { mapCountries, mapSectors } from './signUpUtils';

const sortResponse = (response) => {
  const sortedResponse = _.chain(response)
    .values()
    .map((item) => item.attributes)
    .value();
  return sortedResponse[0];
};

const useBusinessDetailsFetch = (includeAllSectors = false) => {
  const [networkError, setNetworkError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [businessDetails, setBusinessDetails] = useState();
  const [countries, setCountries] = useState();
  const [currency, setCurrency] = useState();
  const [blockchain, setBlockchain] = useState();
  const [sectorData, setSectorData] = useState();

  useEffect(() => {
    let isSubscribed = true;
    api.fetchBusinessDetails().then((response) => {
      if (isSubscribed) {
        if (response.response) {
          const fetchedBusinessDetails = sortResponse(
            response.response.businessDetails
          );
          setBusinessDetails(fetchedBusinessDetails);
          setCurrency(fetchedBusinessDetails?.settlementCurrency);
          setBlockchain(fetchedBusinessDetails?.settlementBlockchain);
        } else {
          setNetworkError(true);
          setIsLoading(false);
        }
      }
    });
    return function cleanup() {
      isSubscribed = false;
    };
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    let isSubscribed = true;

    const fetchSectors = async () => {
      const { response } = await api.fetchSectors();
      if (isSubscribed) {
        if (response) {
          setSectorData(mapSectors(response.sector, includeAllSectors));
          setIsLoading(false);
        } else {
          setNetworkError(true);
          setIsLoading(false);
        }
      }
    };

    if (!sectorData) fetchSectors();

    return function cleanup() {
      isSubscribed = false;
    };
  }, [businessDetails, countries, sectorData]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchCountries = async () => {
      const { response } = await api.fetchBankCountries();
      if (isSubscribed) {
        if (response) {
          setIsLoading(false);
          setCountries(mapCountries(response.country));
        } else {
          setNetworkError(true);
          setIsLoading(false);
        }
      }
    };

    if (!countries) fetchCountries();

    return function cleanup() {
      isSubscribed = false;
    };
  }, [sectorData]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  return [
    businessDetails,
    countries,
    currency,
    blockchain,
    isLoading,
    networkError,
    sectorData,
  ];
};

export default useBusinessDetailsFetch;
